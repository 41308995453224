import React, { ErrorInfo, ReactNode, Component } from 'react';

import './ErrorBoundary.scss';
import Button from '../Button';

interface Props {
  children?: ReactNode;
}

interface IState {
  /** Flag to indicate if error captured or not */
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false,
    };

    this.refreshPage = this.refreshPage.bind(this);
  }

  // Learn more why used at https://reactjs.org/docs/error-boundaries.html
  static getDerivedStateFromError(): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    if (process.env.NODE_ENV === 'development') {
      console.log({ error: error, errorInfo: info });
    }
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <section className="errorBoundary">
          <h4>
            <span>Oops, looks like there is some problem we are facing. Please check in later.</span>
          </h4>
          <Button onClick={this.refreshPage}>Refresh Page</Button>
        </section>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

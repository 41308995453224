import React, { useState } from 'react';

import './Home.scss';
import ApplicationAccepted from '../ApplicationAccepted';
import ConnectionRequest from '../ConnectionRequest';

const Home = () => {
  const [submitted, setSubmitted] = useState(false);
  const [connectionRequestData, setConnectionRequestData] = useState<any>({});
  return (
    <>
      {!submitted ? (
        <ConnectionRequest setSubmitted={setSubmitted} setConnectionRequestData={setConnectionRequestData} />
      ) : (
        <ApplicationAccepted setSubmitted={setSubmitted} data={connectionRequestData} />
      )}
    </>
  );
};

export default Home;

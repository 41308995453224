import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Page404.scss';
import Button from 'components/Button';

const Page404: React.FC<any> = () => {
  const navigate = useNavigate();
  return (
    <main className="notFound">
      <h3>Page Not Found!!</h3>
      <h6>Unfortunately the page you're looking for doesn't exist.</h6>
      <Button onClick={() => navigate(-1)}>Go to Previous Page</Button>
    </main>
  );
};

export default Page404;

import React, { Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ErrorBoundary from 'components/ErrorBoundary';
import Home from 'pages/Home/Home';
import Page404 from 'pages/Page404';

import { FallBackLoader } from './components/Loader';
import TermsOfService from './pages/TermsOfService';

function App() {
  return (
    <>
      <ErrorBoundary>
        <Router>
          <Suspense fallback={<FallBackLoader />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Suspense>
        </Router>
      </ErrorBoundary>
      <ToastContainer position="bottom-right" autoClose={3000} closeOnClick icon={false} />
    </>
  );
}

export default App;

import clsx from 'clsx';
import React from 'react';

interface IProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'outlined' | 'contained' | 'text' | 'loading';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
}

const Button: React.FC<IProps> = ({ children, variant = 'contained', type = 'button', ...props }) => {
  return (
    <button type={type} {...props} className={clsx('btn', `btn--${variant}`)}>
      {children}
    </button>
  );
};

export default Button;
